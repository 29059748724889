<template>
	<div class="s-layout" :style="colorObject">
    <div class="s-container">
		<HeaderV3
			:meta="meta"
			:page-title="moduleConfig.page_title"
			:page-image="moduleConfig.page_image"
			:page-kv-image="moduleConfig.page_kv_image"
			:header-section="moduleConfig.header_section"
		/>
		
    <div class="page">
      <router-view />
    </div>
	</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import HeaderV3 from '@/components/Page/Liff/Shared/HeaderV3'
import { themeColorFn } from "@/mixins/liff/themeColor";

export default {
	mixins: [themeColorFn({ themeConfigPage: 'reservation' })],
	components: {
		HeaderV3
	},
	computed: {
		...mapGetters({
			meta: "liffGeneral/meta",
      themeConfig: 'liffGeneral/themeConfig',
      getModule: 'liffModule/getModule',
    }),
    moduleConfig() {
      return this.getModule('liff_reservation').module_config || {}
    },
	},

}

</script>


<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;

    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }

  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}
</style>
